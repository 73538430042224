import React, { Component } from 'react';
import Slider from 'react-slick';

class TestimonialSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings} className="testimonial_slider">

          <div className="item">
            <h6>Kate Alexander RN</h6>
            <span>United States</span>
            <p>I couldn't be more thrilled with the apps Daletech created for me! Their team was professional, innovative, and attentive to all my needs. The apps are not only user-friendly but also beautifully designed and highly functional. Daletech exceeded my expectations in every way. I highly recommend their services to anyone looking for top-notch app development!</p>
          </div>

          <div className="item">
            <h6>Angela</h6>
            <span>United States</span>
            <p>Daletech delivered an outstanding app that is  both functional and beautifully designed. Their team's professionalism and attention to detail made the entire process smooth and enjoyable. Highly recommended!</p>
          </div>

          <div className="item">
            <h6>Laura V</h6>
            <span>United States</span>
            <p>Daletech truly exceeded my expectations with my CCRN app. Their team demonstrated remarkable skill and dedication, creating a seamless, user-friendly experience that our users love. Their professionalism and attention to detail were outstanding. I highly recommend Daletech for any app development needs!</p>
          </div>

        </Slider>
      </div>
    );
  }
}
export default TestimonialSlider;
import React, { Component } from 'react';
import { Reveal } from 'react-reveal/';
import { Link } from 'react-scroll';
import banner from '../../image/banner_img1.svg'

class frelencerBanner extends Component {
    render() {
        return (
            <section className="frelencer_banner_area" id="home">
                <div className="home_bubble">
                    {/* <div className="bubble b_one"></div> */}
                    {/* <div className="bubble b_two"></div> */}
                    <div className="bubble b_three"></div>
                    {/* <div className="bubble b_four"></div> */}
                    {/* <div className="bubble b_five"></div> */}
                    <div className="bubble b_six"></div>
                </div>
                <Reveal effect="fadeInRight" duration={500}>
                    <img className="s_shap" src={banner} alt="" />
                </Reveal>
                <div className="container" style={{marginTop:-39}}>
                    <div className="row">
                        <div className="col-lg-6 intro-white"  style={{backgroundColor:'white', paddingTop:20, paddingBottom:20, borderBottomLeftRadius:20, borderBottomRightRadius:20}}>
                            <div className="frelencer_content">
                                <Reveal effect="fadeInUp">
                                    <h2 className="t_color">Have a vision/idea?</h2>
                                    <div className="bubble b_three"></div>
                                </Reveal>
                                <Reveal effect="fadeInUp" duration={1500}>
                                    <p className="mb_0" style={{ marginTop: -20, fontSize: 26 }}>Let's elevate it into a brand!</p>
                                </Reveal>
                                <Reveal effect="fadeInUp" duration={1500}>
                                    <p className="mb_0" style={{ fontSize: 16 }}>Our team of expert developers is here for you -- 24/7 ready to design and develop cutting-edge web, Android, and iOS applications tailored just for you!</p>
                                </Reveal>
                                <Link
                                    onClick={()=>{
                                        console.log('click');
                                    }}
                                    className='btn my-button' style={{color:'white',marginTop:10,width:150,padding:12,fontSize:18,fontStyle:'bold',border:'none'}} to="contact" spy={true} smooth={false} offset={-66} duration={1000}>
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="frelencer_img">
                                <Reveal effect="fadeInLeft" duration={1400}>
                                    <img className="f_img_one" src={require('../../image/f_man.png')} alt=""/>
                                </Reveal>
                                <Reveal effect="fadeInRight" duration={1200}>
                                    <img className="f_img_two" src={require('../../image/table.png')} alt="" />
                                </Reveal>
                                <Reveal effect="fadeInDown" duration={1600}>
                                    <img className="f_img_three" src={require('../../image/cup.png')} alt=""/>
                                </Reveal>
                                <Reveal effect="fadeInDown" duration={1600}>
                                    <img className="f_img_four" src={require('../../image/shadow.png')} alt="" />
                                </Reveal>
                                <Reveal effect="fadeInDown" duration={2000}>
                                    <div className="f_img_five">
                                        <img className="" src={require('../../image/php.png')} alt="" />
                                    </div>
                                </Reveal>
                                <div className="f_img_six">
                                    <img className="" src={require('../../image/c++.png')} alt="" />
                                </div>
                                <div className="f_img_seven">
                                    <img className="" src={require('../../image/search.png')} alt="" />
                                </div>
                                <div className="f_img_eight">
                                    <img className="" src={require('../../image/arrow.png')} alt="" />
                                </div>
                                <div className="f_img_nine">
                                    <img className="" src={require('../../image/brakets.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default frelencerBanner;
import React from 'react';
import TestimonialSlider from './TestimonialSlider';
import SectionTitleTwo from '../Banner/SectionTitleTwo';

const Testimonial = () => {
  return (
    <section id="testimonial" style={{ paddingTop: 50, paddingBottom: 50 }}>
      <div className="container">
        <SectionTitleTwo tCenter="text-center" stitle="Testimonials" btitle="What our Clients Say" />
        <div className="testimonial_area">
          <TestimonialSlider />
        </div>
        {/* <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ fontSize: 18, color: 'black', fontWeight: 'bold' }}>
            Want to see more?
          </div>
          <button
            onClick={() => {
              window.open('https://www.freelancer.com/u/saif0347')
              // window.location.href = 'https://www.freelancer.com/u/saif0347'
            }}
            className="btn my-button"
            style={{ color: 'white', width: 300, marginTop: 10, padding: 12, fontSize: 16, border: 'none' }}>
            View Our Freelancing Profile
          </button>
        </div> */}
      </div>
    </section>
  )
}
export default Testimonial;
import React, { useState, useEffect } from 'react';
import FooterTwo from '../../component/FooterTwo';
import NavbarShopifyToApp from './../Navbar/NavbarShopifyToApp';
import jhonData from '../../component/jhonData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'

const Plans = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    let profilePercentage = 100

    const [platform, setPlatfrom] = useState(1);
    const [showFeatures, setShowFeatures] = useState(0);

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step1) {
                console.log('platform: ' + shopifyData.step1.platform);
                setPlatfrom(shopifyData.step1.platform)
            }
        }
    }, [])

    const orderNowClick = (platform) => {
        let shopifyDataTemp = {
            ...shopifyData,
            step1: {
                ...shopifyData.step1,
                platform: platform
            }
        }
        dispatch(Actions.setShopifyData(shopifyDataTemp))
        navigate('/shopify-payment', { replace: false });
    };

    const showCrossTick = (tick, txt) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    style={{ width: 18, height: 18, backgroundColor: tick ? '#CEF6D8' : '#F8E0E0', borderRadius: 10, padding: 3 }}
                    src={tick ? require('../../image/itick.png') : require('../../image/icross.png')} />
                <div style={{ marginLeft: 8, fontSize: 14 }}>
                    {txt}
                </div>
            </div>
        )
    };

    const showPlanFeatures = (releasable) => {
        return (
            <div style={{ marginTop: 10 }}>
                {showCrossTick(releasable, 'Unlimited Revisions')}
                {showCrossTick(releasable, 'Store Releasable')}
                {showCrossTick(true, 'Push Notifications')}
                {showCrossTick(true, 'Google AdMob Banner')}
                {showCrossTick(true, 'Customize App Icon')}
                {showCrossTick(true, 'Customize Splash Screen')}
                {showCrossTick(true, 'Bottom Tabs')}
                {showCrossTick(true, 'Share App')}
                {showCrossTick(true, 'Rate App')}
                {showCrossTick(true, 'Pull To Refresh')}
                {/* {showCrossTick(true, 'App Permissions Handling')} */}
                {showCrossTick(true, 'Status Bar Customization')}
                {showCrossTick(true, 'Page Loader')}
                {showCrossTick(true, 'Pinch To Zoom')}
                {showCrossTick(true, 'No Internet Screen')}
                {showCrossTick(true, 'App Syncing With Store')}
                {showCrossTick(true, 'Customize Package Name')}
                {showCrossTick(true, 'Customize Version Code')}
                {showCrossTick(true, 'Email Support')}
                {showCrossTick(true, 'Whatsapp Support')}
            </div>
        )
    };

    return (
        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>

            <button
                aria-label="Scroll to top"
                type="button"
                className="scroll-top"
                onClick={() => {
                    window.open("https://api.whatsapp.com/send?text=I want to convert my shopify store to a Mobile App.&phone=+923074052841", '_blank')
                }}>
                <img
                    src={require('../../image/wa1.png')}
                    style={{ alignSelf: 'center', width: 35, height: 35, tintColor: '#04B486', marginTop: -3 }}
                    alt=""
                />
            </button>

            <NavbarShopifyToApp mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" />
            <div style={{ display: 'flex', marginTop: 100, height: 70, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    style={{ width: 70, height: 70 }}
                    src={require('../../image/shopify.png')} />
                <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', marginTop: 30, marginLeft: 10, color: '#113E49' }}>
                    Mobile App Builder
                </h3>
            </div>
            <div style={{ display: 'flex', width: '80%', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                <div style={{ height: 8, width: '100%', backgroundColor: '#f2f2f2', marginTop: 50, borderRadius: 4 }}>
                    <div style={{ height: 8, backgroundColor: '#04B486', width: profilePercentage + '%', borderRadius: 4 }} />
                </div>

                <div
                    className='col'
                    style={{ flex: 1, marginTop: 30, display: 'flex', flexDirection: 'column' }}>

                    <div id='pricing' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                        <div style={{ marginTop: 40, display: 'flex', flexDirection: 'column' }}>
                            <h3 className="web-to-app-title" style={{ marginBottom: 25, fontFamily: 'sans-serif', textAlign: 'center', color: 'gray' }}>
                                <span style={{ color: '#FE2E64' }}>Stop</span> paying <span style={{ color: '#FE2E64' }}>$99/month!</span><br /> And get <span style={{ color: '#04B486', fontWeight: 'normal' }}>Lifetime Apps</span> for CRAZY LOW... 👇
                            </h3>

                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 0 }}>

                                <div style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: platform == 1 ? '1px solid #FA5858' : '1px solid lightgray', padding: 20 }}>
                                    <div style={{ color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>$29 <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>
                                    <div style={{ marginTop: 5, color: '#113E49', fontWeight: 'bold', fontSize: 22 }}>
                                        Android
                                    </div>
                                    <div style={{ lineHeight: 1.5, marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 14 }}>
                                        Complete Android App Including All the Features Below.
                                    </div>
                                    <button
                                        style={{ alignSelf: 'center', marginTop: 15, width: 200, fontSize: 16, border: '0px', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 40 }}
                                        onClick={() => {
                                            orderNowClick(1)
                                        }}>
                                        Order Now
                                    </button>
                                    {window.innerWidth <= 700 &&
                                        <button
                                            style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                            onClick={() => {
                                                if (showFeatures == 2)
                                                    setShowFeatures(0)
                                                else
                                                    setShowFeatures(2)
                                            }}>
                                            <div
                                                style={{ marginTop: 0, color: '#04B486' }}>
                                                {showFeatures != 2 ? 'Show Features' : 'Hide Features'}
                                            </div>
                                            <img
                                                style={{ width: 20, height: 20, marginLeft: 5 }}
                                                src={require('../../image/down-arrow1.png')} />
                                        </button>
                                    }
                                    {(window.innerWidth > 700 || showFeatures == 2) &&
                                        showPlanFeatures(true)
                                    }
                                </div>

                                <div className='ml-10' style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: platform == 2 ? '1px solid #FA5858' : '1px solid lightgray', padding: 20 }}>
                                    <div style={{ color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>$49 <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>
                                    <div style={{ marginTop: 5, color: '#113E49', fontWeight: 'bold', fontSize: 22 }}>
                                        iOS
                                    </div>
                                    <div style={{ lineHeight: 1.5, marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 14 }}>
                                        Complete iOS App Including All the Features Below.
                                    </div>
                                    <button
                                        style={{ alignSelf: 'center', marginTop: 15, width: 200, fontSize: 16, border: '0px', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 40 }}
                                        onClick={() => {
                                            orderNowClick(2)
                                        }}>
                                        Order Now
                                    </button>
                                    {window.innerWidth <= 700 &&
                                        <button
                                            style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                            onClick={() => {
                                                if (showFeatures == 4)
                                                    setShowFeatures(0)
                                                else
                                                    setShowFeatures(4)
                                            }}>
                                            <div
                                                style={{ marginTop: 0, color: '#04B486' }}>
                                                {showFeatures != 4 ? 'Show Features' : 'Hide Features'}
                                            </div>
                                            <img
                                                style={{ width: 20, height: 20, marginLeft: 5 }}
                                                src={require('../../image/down-arrow1.png')} />
                                        </button>
                                    }
                                    {(window.innerWidth > 700 || showFeatures == 4) &&
                                        showPlanFeatures(true)
                                    }
                                </div>

                                <div className='ml-10' style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: platform == 3 ? '1px solid #FA5858' : '1px solid lightgray', padding: 20 }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>$69 <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>
                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <div style={{ fontSize: 12, backgroundColor: '#F8E0E0', color: '#FA5858', borderRadius: 20, paddingLeft: 10, paddingRight: 10 }}>
                                                BEST VALUE
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 5, color: '#113E49', fontWeight: 'bold', fontSize: 22 }}>
                                        Android & iOS
                                    </div>
                                    <div style={{ lineHeight: 1.5, marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 14 }}>
                                        Both Android & iOS App Including All the Features Below.
                                    </div>
                                    <button
                                        style={{ alignSelf: 'center', marginTop: 15, width: 200, fontSize: 16, border: '0px', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 40 }}
                                        onClick={() => {
                                            orderNowClick(3)
                                        }}>
                                        Order Now
                                    </button>
                                    {window.innerWidth <= 700 &&
                                        <button
                                            style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                            onClick={() => {
                                                if (showFeatures == 3)
                                                    setShowFeatures(0)
                                                else
                                                    setShowFeatures(3)
                                            }}>
                                            <div
                                                style={{ marginTop: 0, color: '#04B486' }}>
                                                {showFeatures != 3 ? 'Show Features' : 'Hide Features'}
                                            </div>
                                            <img
                                                style={{ width: 20, height: 20, marginLeft: 5 }}
                                                src={require('../../image/down-arrow1.png')} />
                                        </button>
                                    }
                                    {(window.innerWidth > 700 || showFeatures == 3) &&
                                        showPlanFeatures(true)
                                    }
                                </div>

                            </div>

                            <img
                                style={{ marginTop: 20, alignSelf: 'center', width: window.innerWidth < 800 ? '100%' : '60%' }}
                                src={require('../../image/banner1.jpg')} />

                            <div style={{ marginTop: 20, fontSize: 18, color: 'black', fontFamily: 'sans-serif' }}>
                                <span style={{ color: 'orange' }}>Note:</span> You will get testable app within 24 hours. And if you do not like the mobile app, we will refund 100% payment without any question.
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <FooterTwo jhonData={jhonData} />
        </div>
    )
}

export default Plans; 
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Link } from 'react-scroll';
import showToast from '../toast';
import FooterTwo from '../../component/FooterTwo';
import NavbarShopifyToApp from './../Navbar/NavbarShopifyToApp';
import jhonData from '../../component/jhonData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'

const Success = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    const [email, setEmail] = useState('');
    const [platform, setPlatfrom] = useState(1);

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step1) {
                setEmail(shopifyData.step1.email)
                setPlatfrom(shopifyData.step1.platform)
            }
        }
    }, [])

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
            <NavbarShopifyToApp mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" />
            <div style={{ display: 'flex', marginTop: 100, height: 70, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    style={{ width: 70, height: 70 }}
                    src={require('../../image/shopify.png')} />
                <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', marginTop: 30, marginLeft: 10, color: '#113E49' }}>
                    Mobile App Builder
                </h3>
            </div>
            <div className='shopify-step-parent' style={{ height: '80vh', display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'center' }}>

                <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column', padding: 50, border: '1px solid lightgray', borderRadius: 10 }}>
                    <div style={{ fontSize: 32, color: '#04B486', textAlign: 'center' }}>
                        Congratulations!
                    </div>
                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                        Your order for Android App is confirmed! Within 24 hours our developer will reach you out sending a testable build. You can test and ask for any customizations. Please stay tuned :)
                    </div>
                </div>

            </div>
            <FooterTwo jhonData={jhonData} />
        </div>
    )
}

export default Success;
import React, { useState, useEffect } from 'react';
import { Reveal } from 'react-reveal/';
import { Link } from 'react-scroll';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import WebToAppPortfolioSlider from '../../component/Portfolio/WebToAppPortfolioSlider';
import TestimonialWebToApp from '../../component/Testimonial/TestimonialWebToApp';
import WebToAppFAQs from '../../component/WebToAppFAQs';

const WebToAppBanner = () => {

    const [url, setUrl] = useState('')
    const [expandedItem, setExpandedItem] = useState(-1);
    const [showFeatures, setShowFeatures] = useState(0);
    const features = [
        {
            title: 'OneSignal Notifications',
            icon: require('../../image/feature1.png'),
            desc: 'Send unlimited push notifications via OneSignal Panel.'
        },
        {
            title: 'Admob Banner Ads',
            icon: require('../../image/feature2.png'),
            desc: 'Earn extra income using admob banner ads by Google.'
        },
        {
            title: 'Splash Screen',
            icon: require('../../image/f3.png'),
            desc: 'A professional looking splash screen with your logo and branding.'
        },
        {
            title: 'Downloads & Uploads',
            icon: require('../../image/f4.png'),
            desc: 'Ability to download and upload any files.'
        },
        {
            title: 'Navigation Drawer (Menu)',
            icon: require('../../image/f6.png'),
            desc: 'We can add navigation drawer menu in the app upon request. Giving extra native look and navigation options.'
        },
        {
            title: 'Pull to Refresh',
            icon: require('../../image/f5.png'),
            desc: 'Native pull to refresh feature to reload the website.'
        },
        {
            title: 'Bottom Tabs (Menu)',
            icon: require('../../image/f7.png'),
            desc: 'We can add bottom tabs in the app upon request. Giving extra native look and navigation options.'
        },
        {
            title: 'Geolocation Support',
            icon: require('../../image/f8.png'),
            desc: 'Ability to access user location within app including permission handing.'
        },
        {
            title: 'App Linking & Sharing',
            icon: require('../../image/f9.png'),
            desc: 'Users can share your app on social medias growing your audience.'
        },
        {
            title: 'No Internet Screen',
            icon: require('../../image/f10.png'),
            desc: 'A cool No Internet Screen whenever user is offline.'
        },
        {
            title: 'Loading Animation',
            icon: require('../../image/f11.png'),
            desc: 'A loading indicator whenever the website is loading.'
        },
        {
            title: 'Rate App Dialog',
            icon: require('../../image/f12.png'),
            desc: 'Get 5 star ratings from your users boosting your rank on app stores.'
        },
    ]

    const premFeatures = [
        {
            title: 'Playstore Publishing',
            icon: require('../../image/f13.png'),
            desc: 'We will publish your app to google play store, managing all the technical stuff.'
        },
        {
            title: 'Apple Store Publishing',
            icon: require('../../image/f14.png'),
            desc: 'We will publish your app to apple store, managing all the technical stuff.'
        },
        {
            title: 'Firebase Analytics',
            icon: require('../../image/f15.png'),
            desc: 'Get all technical analysis & stats of your app via Firebase Analytics.'
        },
        {
            title: 'In-App Purchases',
            icon: require('../../image/f16.png'),
            desc: 'Add in-app payments so you can earn more revenue.'
        },
        {
            title: 'Barcode/QR Code Scanner',
            icon: require('../../image/f17.png'),
            desc: 'Ability to scan any barcode or QR code via phone camera.'
        },
        {
            title: 'Touch ID / Face ID / Biometric Authentication',
            icon: require('../../image/f18.png'),
            desc: 'Integration of Touch ID / Face ID / Biometric Authentication for quick access.'
        },
        {
            title: 'Background Location Service',
            icon: require('../../image/f19.png'),
            desc: 'Background location service for Android, to fetch and submit user current location to your server.'
        },
        {
            title: 'And More...',
            icon: require('../../image/f20.png'),
            desc: 'Will add any other features you want.'
        },
    ]

    const showItem = (itemNo, item) => {
        return (
            <div className={itemNo % 2 != 0 ? 'web-to-app-feature-item-left' : ''} style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <button
                    className='web-to-app-feature-item'
                    style={{ border: '0px', textAlign: 'start', marginTop: 10, flex: 1, display: 'flex', alignItems: 'center', backgroundColor: '#f2f2f2', padding: 10, borderRadius: 50, minHeight: 90 }}
                    onClick={() => {
                        if (expandedItem == itemNo) {
                            setExpandedItem(-1)
                            return
                        }
                        setExpandedItem(itemNo)
                    }}>
                    <div style={{ display: 'flex', width: 70, height: 70, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', borderRadius: 40 }}>
                        <img
                            style={{ width: 40, height: 40 }}
                            src={item.icon} />
                    </div>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <div className='web-to-app-feature-item-txt'>
                            {item.title}
                        </div>
                    </div>
                    <div className={expandedItem == itemNo ? 'web-to-app-feature-item-arrow' : ''} style={{ display: 'flex', marginRight: 15 }}>
                        <img
                            style={{ width: 30, height: 30 }}
                            src={require('../../image/down-arrow.png')} />
                    </div>
                </button>
                {expandedItem == itemNo &&
                    <Reveal effect="fadeInUp" duration={1000}>
                        <div className='web-to-app-feature-item-txt' style={{ fontSize: 14, marginTop: 5 }}>
                            {item.desc}
                        </div>
                    </Reveal>
                }
            </div>
        )
    };

    const showStep = (step, stepTxt, stepDesc) => {
        return (
            <div className='step-parent' style={{ flex: 1, marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                <div className='step'>
                    <div>{step}</div>
                </div>
                <div className='step-txt' style={{ fontWeight: 'bold', fontSize: 18 }}>
                    {stepTxt}
                </div>
                <div className='step-txt' style={{ marginTop: 0, fontSize: 16, color: 'gray' }}>
                    {stepDesc}
                </div>
            </div>
        )
    };

    const showCrossTick = (tick, txt) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    style={{ width: 18, height: 18, backgroundColor: tick ? '#CEF6D8' : '#F8E0E0', borderRadius: 10, padding: 3 }}
                    src={tick ? require('../../image/itick.png') : require('../../image/icross.png')} />
                <div style={{ marginLeft: 8, fontSize: 14 }}>
                    {txt}
                </div>
            </div>
        )
    };

    const showPlanFeatures = (releasable) => {
        return (
            <div style={{ marginTop: 10 }}>
                {showCrossTick(releasable, 'Store Releasable')}
                {showCrossTick(true, 'App Push Notification')}
                {showCrossTick(true, 'Google AdMob')}
                {showCrossTick(true, 'Customize App Icon')}
                {showCrossTick(true, 'Customize Splash Screen')}
                {showCrossTick(true, 'Navigation Drawer')}
                {showCrossTick(true, 'Bottom Tabs')}
                {showCrossTick(true, 'Pull To Refresh')}
                {showCrossTick(true, 'App Permissions Handling')}
                {showCrossTick(true, 'Customize Package Name')}
                {showCrossTick(true, 'Customize Version Code')}
                {showCrossTick(true, 'Status Bar Customization')}
                {showCrossTick(true, 'Manage Screen Orientation')}
                {showCrossTick(true, 'Page Loader')}
                {showCrossTick(true, 'Pinch To Zoom')}
                {showCrossTick(true, 'Geo Location Support')}
                {showCrossTick(true, 'Downloads & Uploads')}
                {showCrossTick(true, 'Media Playback')}
                {showCrossTick(true, 'No Internet Connection')}
                {showCrossTick(true, 'App Syncing With Website')}
                {showCrossTick(true, 'Deep Linking')}
                {showCrossTick(true, 'Advance URL Handling')}
                {showCrossTick(true, 'CSS Overrides')}
                {showCrossTick(true, 'Email Support')}
                {showCrossTick(true, 'Whatsapp Support')}
            </div>
        )
    };

    const openChat = (type) => {
        if (url === '')
            window.open("https://api.whatsapp.com/send?text=I want to convert my website to a " + type + " App.&phone=+923074052841", '_blank')
        else
            window.open("https://api.whatsapp.com/send?text=I want to convert my website" + url + " to a " + type + " App.&phone=+923074052841", '_blank')
        // window.location.href = "whatsapp://send?text=I want to convert my website " + url + " to a " + type + " App.&phone=+923074052841"
    };

    return (
        <section className="" id="home" style={{}}>
            {/* <div className="home_bubble" style={{ visibility: window.innerWidth < 700 ? 'hidden' : 'visible' }}>
                <div className="bubble b_one"></div>
                <div className="bubble b_four"></div>
                <div className="bubble b_five"></div>
                <div className="bubble b_six"></div>
                <div className="bubble b_three"></div>
            </div> */}
            <button
                aria-label="Scroll to top"
                type="button"
                className="scroll-top"
                onClick={() => {
                    openChat('Mobile')
                }}>
                {/* <IoIosArrowRoundUp /> */}
                <img
                    src={require('../../image/wa1.png')}
                    style={{ alignSelf: 'center', width: 35, height: 35, tintColor: '#04B486', marginTop: -3 }}
                    alt=""
                />
            </button>

            <div style={{ width: '100%', marginTop: 75, backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'center' }}>
                <div className="row">
                    <div className="intro-white">
                        <div className="">
                            <Reveal effect="fadeInUp">
                                <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                                    Convert Your Website to Mobile Apps!
                                </h3>
                                <div className="bubble b_three"></div>
                            </Reveal>
                            <Reveal effect="fadeInUp" duration={1500}>
                                <p className="mb_0" style={{ fontSize: 16, marginTop: -5, textAlign: 'center', color: '#545454' }}>Use your website URL to see what your app would look like on Android & iOS for free!</p>
                            </Reveal>

                            <FormGroup className='web-to-app-input-row' style={{ marginTop: -10, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                <div style={{ flex: 3, display: 'flex', alignItems: 'center', marginTop: 10, marginRight: 10 }}>
                                    <Input
                                        style={{ height: 60, minWidth: 300 }}
                                        type='text'
                                        placeholder='https://'
                                        value={url}
                                        onChange={(event) => {
                                            setUrl(event.target.value)
                                        }} />
                                    {url.length < 1 ?
                                        <button
                                            onClick={async () => {
                                                console.log('click');
                                                const text = await navigator.clipboard.readText();
                                                setUrl(text)
                                            }}
                                            style={{ color: '#04B486', border: '0px', backgroundColor: '#f2f2f2', marginLeft: -110, width: 100, textDecoration: 'underline' }}>
                                            Paste Link
                                        </button>
                                        :
                                        <button
                                            onClick={async () => {
                                                setUrl('')
                                            }}
                                            style={{ color: '#04B486', border: '0px', backgroundColor: '#f2f2f2', marginLeft: -70, width: 60, textDecoration: 'underline' }}>
                                            Clear
                                        </button>
                                    }
                                </div>
                                <div style={{ display: 'flex', minWidth: 280, flex: 1 }}>
                                    <Link
                                        onClick={() => {
                                            console.log('click');
                                            openChat('Demo')
                                        }}
                                        className='btn my-button' style={{ backgroundColor: '#04B486', color: 'white', marginTop: 10, width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                        Request Now, IT'S FREE
                                    </Link>
                                </div>
                            </FormGroup>

                            <div style={{ alignSelf: 'center', marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img
                                    className='web-to-app-image'
                                    style={{}}
                                    src={require('../../image/web-to-app.png')} />
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ marginBottom: 30, fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                            App Features You Will Get!
                        </h3>

                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {features.map((item, index) => {
                                return showItem(index, item)
                            })}
                        </div>

                        <h3 className="web-to-app-title" style={{ marginTop: 30, fontSize: 26, fontFamily: 'sans-serif', textAlign: 'center', color: '#04B486' }}>
                            Premium Addon Features!
                        </h3>
                        <Reveal effect="fadeInUp" duration={1500}>
                            <p className="" style={{ fontSize: 16, marginBottom: 15, marginTop: -5, textAlign: 'center', color: '#545454' }}>
                                These features will cost you extra!
                            </p>
                        </Reveal>

                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {premFeatures.map((item, index) => {
                                return showItem(index, item)
                            })}
                        </div>

                    </Reveal>
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ marginBottom: 30, fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                            Get Your App in Just 3 Steps!
                        </h3>
                        <div
                            style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 10, alignItems: 'flex-start', justifyContent: 'center' }}>
                            {showStep(1, 'Submit Url', 'Enter the website link you would like to convert into a mobile app.')}
                            {showStep(2, 'Get Free Demo', 'I will get details and give a FREE demo within few hours.')}
                            {showStep(3, 'Upload to Stores', 'Upload the app on App Store or Play Store.')}
                        </div>
                    </Reveal>
                </div>
            </div>


            <div id='pricing' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <h3 className="web-to-app-title" style={{ marginBottom: 25, fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                        Pay Once, Access Lifetime!
                    </h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 15 }}>

                        <div style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: '1px solid lightgray', padding: 20 }}>
                            <div style={{ color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>$0 <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>
                            <div style={{ marginTop: 5, color: '#113E49', fontWeight: 'bold', fontSize: 22 }}>
                                Free
                            </div>
                            <div style={{ lineHeight: 1.5, marginTop: 15, color: 'gray', fontWeight: 'bold', fontSize: 14 }}>
                                This is for testing purpose, expires in 10 days.
                            </div>
                            <button
                                style={{ alignSelf: 'center', marginTop: 10, width: 150, fontSize: 15, border: '0px', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 35 }}
                                onClick={() => {
                                    openChat('Demo')
                                }}>
                                Choose Plan
                            </button>
                            {window.innerWidth <= 700 &&
                                <button
                                    style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                    onClick={() => {
                                        if (showFeatures == 1)
                                            setShowFeatures(0)
                                        else
                                            setShowFeatures(1)
                                    }}>
                                    <div
                                        style={{ marginTop: 0, color: '#04B486' }}>
                                        {showFeatures != 1 ? 'Show Features' : 'Hide Features'}
                                    </div>
                                    <img
                                        style={{ width: 20, height: 20, marginLeft: 5 }}
                                        src={require('../../image/down-arrow1.png')} />
                                </button>
                            }
                            {(window.innerWidth > 700 || showFeatures == 1) &&
                                showPlanFeatures(false)
                            }
                        </div>

                        <div className='ml-10' style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: '1px solid lightgray', padding: 20 }}>
                            <div style={{ color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>$29 <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>
                            <div style={{ marginTop: 5, color: '#113E49', fontWeight: 'bold', fontSize: 22 }}>
                                Android
                            </div>
                            <div style={{ lineHeight: 1.5, marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 14 }}>
                                Complete Android App Including All the Features Below.
                            </div>
                            <button
                                style={{ alignSelf: 'center', marginTop: 15, width: 150, fontSize: 15, border: '0px', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 35 }}
                                onClick={() => {
                                    openChat('Android')
                                }}>
                                Choose Plan
                            </button>
                            {window.innerWidth <= 700 &&
                                <button
                                    style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                    onClick={() => {
                                        if (showFeatures == 2)
                                            setShowFeatures(0)
                                        else
                                            setShowFeatures(2)
                                    }}>
                                    <div
                                        style={{ marginTop: 0, color: '#04B486' }}>
                                        {showFeatures != 2 ? 'Show Features' : 'Hide Features'}
                                    </div>
                                    <img
                                        style={{ width: 20, height: 20, marginLeft: 5 }}
                                        src={require('../../image/down-arrow1.png')} />
                                </button>
                            }
                            {(window.innerWidth > 700 || showFeatures == 2) &&
                                showPlanFeatures(true)
                            }
                        </div>

                        <div className='ml-10' style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: '1px solid #FA5858', padding: 20 }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>$69 <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <div style={{ fontSize: 12, backgroundColor: '#F8E0E0', color: '#FA5858', borderRadius: 20, paddingLeft: 10, paddingRight: 10 }}>
                                        BEST VALUE
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: 5, color: '#113E49', fontWeight: 'bold', fontSize: 22 }}>
                                Android & iOS
                            </div>
                            <div style={{ lineHeight: 1.5, marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 14 }}>
                                Both Android & iOS App Including All the Features Below.
                            </div>
                            <button
                                style={{ alignSelf: 'center', marginTop: 15, width: 150, fontSize: 15, border: '0px', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 35 }}
                                onClick={() => {
                                    openChat('Android and iOS')
                                }}>
                                Choose Plan
                            </button>
                            {window.innerWidth <= 700 &&
                                <button
                                    style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                    onClick={() => {
                                        if (showFeatures == 3)
                                            setShowFeatures(0)
                                        else
                                            setShowFeatures(3)
                                    }}>
                                    <div
                                        style={{ marginTop: 0, color: '#04B486' }}>
                                        {showFeatures != 3 ? 'Show Features' : 'Hide Features'}
                                    </div>
                                    <img
                                        style={{ width: 20, height: 20, marginLeft: 5 }}
                                        src={require('../../image/down-arrow1.png')} />
                                </button>
                            }
                            {(window.innerWidth > 700 || showFeatures == 3) &&
                                showPlanFeatures(true)
                            }
                        </div>

                        <div className='ml-10' style={{ display: 'flex', marginTop: 10, minWidth: 250, flex: 1, flexDirection: 'column', borderRadius: 20, border: '1px solid lightgray', padding: 20 }}>
                            <div style={{ color: '#113E49', fontWeight: 'bold', fontSize: 28 }}>$49 <span style={{ fontSize: 14, color: 'gray' }}>one time</span></div>
                            <div style={{ marginTop: 5, color: '#113E49', fontWeight: 'bold', fontSize: 22 }}>
                                iOS
                            </div>
                            <div style={{ lineHeight: 1.5, marginTop: 10, color: 'gray', fontWeight: 'bold', fontSize: 14 }}>
                                Complete iOS App Including All the Features Below.
                            </div>
                            <button
                                style={{ alignSelf: 'center', marginTop: 15, width: 150, fontSize: 15, border: '0px', backgroundColor: '#04B486', color: 'white', borderRadius: 5, borderRadius: 30, minHeight: 35 }}
                                onClick={() => {
                                    openChat('iOS')
                                }}>
                                Choose Plan
                            </button>
                            {window.innerWidth <= 700 &&
                                <button
                                    style={{ border: '0px', backgroundColor: 'transparent', padding: 0, display: 'flex', marginTop: 10, alignItems: 'center' }}
                                    onClick={() => {
                                        if (showFeatures == 4)
                                            setShowFeatures(0)
                                        else
                                            setShowFeatures(4)
                                    }}>
                                    <div
                                        style={{ marginTop: 0, color: '#04B486' }}>
                                        {showFeatures != 4 ? 'Show Features' : 'Hide Features'}
                                    </div>
                                    <img
                                        style={{ width: 20, height: 20, marginLeft: 5 }}
                                        src={require('../../image/down-arrow1.png')} />
                                </button>
                            }
                            {(window.innerWidth > 700 || showFeatures == 4) &&
                                showPlanFeatures(true)
                            }
                        </div>
                    </div>

                </div>
            </div>

            <div id='showcase' style={{ width: '100%', backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0 }}>
                <div className="intro-white">
                    <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                        Examples of My Work!
                    </h3>
                    <WebToAppPortfolioSlider />
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <h3 className="web-to-app-title" style={{ marginBottom: 30, fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                        Who Am I & What My Clients Say
                    </h3>
                    <img
                        className='freelancer-img'
                        style={{ width: '100%' }}
                        src={require('../../image/freelancer.jpg')} />
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 5, marginLeft: 20, marginRight: 20 }}>
                        <button
                            style={{ flex: 1, marginTop: 10, border: '0px', minWidth: 300, fontSize: 20, backgroundColor: '#0e87d0', color: 'white', borderRadius: 5, height: 60 }}
                            onClick={() => {
                                window.open('https://www.freelancer.com/u/saif0347', '_blank');
                                // window.location.href = 'https://www.freelancer.com/u/saif0347'
                            }}>
                            View My Freelaner Profile
                        </button>
                        <button
                            className='ml-15'
                            style={{ flex: 1, marginTop: 10, minWidth: 300, fontSize: 20, border: '1px solid #0e87d0', backgroundColor: 'white', color: '#0e87d0', borderRadius: 5, height: 60 }}
                            onClick={() => {
                                window.open('https://www.freelancer.com/u/saif0347', '_blank');
                                // window.location.href = 'https://www.freelancer.com/u/saif0347'
                            }}>
                            View Client Reviews
                        </button>
                    </div>
                    <TestimonialWebToApp />
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                            Let's Do It!
                        </h3>
                        <div className="bubble b_three"></div>
                    </Reveal>
                    <Reveal effect="fadeInUp" duration={1500}>
                        <p className="mb_0" style={{ fontSize: 16, marginTop: -5, textAlign: 'center', color: '#545454' }}>
                            Please enter your website url to convert into mobile app.
                        </p>
                    </Reveal>

                    <FormGroup className='web-to-app-input-row' style={{ marginTop: -10, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        <div style={{ flex: 3, display: 'flex', alignItems: 'center', marginTop: 10, marginRight: 10 }}>
                            <Input
                                style={{ height: 60, minWidth: 300 }}
                                type='text'
                                placeholder='https://'
                                value={url}
                                onChange={(event) => {
                                    setUrl(event.target.value)
                                }} />
                        </div>
                        <div style={{ display: 'flex', minWidth: 280, flex: 1 }}>
                            <Link
                                onClick={() => {
                                    console.log('click');
                                    openChat('Demo')
                                }}
                                className='btn my-button' style={{ backgroundColor: '#04B486', color: 'white', marginTop: 10, width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                Request FREE Demo Now
                            </Link>
                        </div>
                    </FormGroup>
                </div>
            </div>

            <div id='FAQ' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                            Freqently Asked Questions
                        </h3>
                    </Reveal>
                    <WebToAppFAQs />
                    <button
                        style={{ alignSelf: 'center', width: '100%', marginTop: 35, textAlign: 'center', fontSize: 22, border: '0px', backgroundColor: 'transparent', color: '#04B486', borderRadius: 5 }}>
                        Any other questions? Let's Discuss in Whatsapp
                    </button>
                </div>
            </div>
        </section>
    )
}

export default WebToAppBanner;
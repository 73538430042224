import React, { Component } from 'react';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';
import Slider from 'react-slick';
import db, { storage } from '../../firebase/firebase';
import { Rings } from 'react-loader-spinner';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { getMobileView } from '../../mobile_view';

class PortfolioSlider extends Component {

    state = {
        portType: 'iOS',
        models: [
            { cat: 'iOS', photo: require('../../image/portfolio/1.jpg') },
            { cat: 'iOS', photo: require('../../image/portfolio/1.jpeg') },
            { cat: 'iOS', photo: require('../../image/portfolio/2.png') },
            { cat: 'iOS', photo: require('../../image/portfolio/4.jpg') },
            { cat: 'Android', photo: require('../../image/portfolio/1.jpg') },
            { cat: 'Android', photo: require('../../image/portfolio/1.jpeg') },
            { cat: 'Android', photo: require('../../image/portfolio/2.png') },
            { cat: 'Android', photo: require('../../image/portfolio/4.jpg') },
            { cat: 'Website', photo: require('../../image/portfolio/web-1.png') },
            { cat: 'Website', photo: require('../../image/portfolio/web-2.png') },
            { cat: 'Website', photo: require('../../image/portfolio/web-3.png') },
            { cat: 'Website', photo: require('../../image/portfolio/web-4.png') },
        ],
        loader: false,
    }

    componentDidMount() {
        // this.loadData();
    }

    getImageUrl = (path, onSuccess) => {
        storage.ref().child(path).getDownloadURL().then((url) => {
            onSuccess(url);
        })
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: true,
            pauseOnHover: true,
            autoplaySpeed: 3000,
            slidesToShow: this.state.portType === 'Website' ? 1 : 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <section className="portfolio_slider_area" style={{ paddingTop: 50, paddingBottom: 50 }} id="portfolio">
                <div className="container">
                    <SectionTitleTwo tCenter="text-center" stitle="Portfolio" btitle="Some of our works" />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: -20, marginBottom: 40 }}>
                        <div class="btn-group" role="group" aria-label="...">
                            <button
                                onClick={() => {
                                    this.setState({ portType: 'Website' });
                                }}
                                type="button"
                                class={this.state.portType === 'Website' ? "btn btn-info" : "btn btn-light"}
                                style={{ width: 200, color: this.state.portType === 'Website' ? 'white' : 'black', backgroundColor: this.state.portType === 'Website' ? '#04B486' : '#f2f2f2' }}>
                                Websites
                            </button>
                            {/* <button
                                onClick={() => {
                                    this.setState({ portType: 'Android' });
                                }}
                                type="button"
                                class={this.state.portType === 'Android' ? "btn btn-info" : "btn btn-light"}
                                style={{ width: 150, color: this.state.portType === 'Android' ? 'white' : 'black', backgroundColor: this.state.portType === 'Android' ? '#04B486' : '#f2f2f2' }}>
                                Android
                            </button> */}
                            <button
                                onClick={() => {
                                    this.setState({ portType: 'iOS' });
                                }}
                                type="button"
                                class={this.state.portType === 'iOS' ? "btn btn-info" : "btn btn-light"}
                                style={{ width: 200, color: this.state.portType === 'iOS' ? 'white' : 'black', backgroundColor: this.state.portType === 'iOS' ? '#04B486' : '#f2f2f2' }}>
                                Mobile Apps
                            </button>
                        </div>
                    </div>
                    {this.state.loader ?
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Rings
                                type="ThreeDots"
                                color="#04B486"
                                height={70}
                                width={70}
                                visible={true} />
                        </div>
                        :
                        <Slider {...settings} className="portfolio_slider">
                            {
                                this.state.models.map((project, index) => {
                                    if (project.cat !== this.state.portType)
                                        return null
                                    return (
                                        <Fade bottom cascade duration={500}>
                                            <div className="p_item">
                                                {/* <Link to={'project/'+project.cat+'_'+project.title}> */}
                                                <div className="portfolio_content">
                                                    {getMobileView(project.photo, project.cat)}
                                                    {/* <div className="text" style={{ textAlign: 'center' }}>
                                                            <p style={{ fontSize: 16, marginTop: 0, color:'gray' }}>{project.title}</p>
                                                        </div> */}
                                                </div>
                                                {/* </Link> */}
                                            </div>
                                        </Fade>
                                    )
                                })
                            }
                        </Slider>
                    }
                </div>
            </section>
        )
    }
}

export default PortfolioSlider;
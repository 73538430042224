import React, { Component } from "react";
import SectionTitleTwo from "../component/Banner/SectionTitleTwo";
import Form from "./Form";
const Reveal = require("react-reveal/Reveal")

class ContactTwo extends Component {
  render() {
    return (
      <section className="contact-area contact-area-two bg_color" style={{ paddingTop: 50, paddingBottom: 50, backgroundColor: '#fafafa' }} id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="get_info">
                <SectionTitleTwo
                  stitle="Get in Touch"
                  btitle=""
                />
                {/* <div className="media get_item">
                  <img src={require('../image/phone-call.png')} style={{ width: 50, height: 50, marginBottom: 15 }} alt="" />
                  <div className="media-body">
                    <h6>Call Me / Whatsapp</h6>
                    <p style={{fontSize:22}}>+923074052841</p>
                  </div>
                </div> */}
                <div className="media get_item">
                  <img style={{ width: 50, height: 50, marginRight: 15, marginTop: -10 }} src={require('../image/email.png')} />
                  <div className="media-body">
                    <h6>Mail Me</h6>
                    <button
                      onClick={()=>{
                        window.open('mailto:ddsurg@ddsurgical.com')
                      }}
                      style={{background:'transparent', marginLeft:-7, border:'0px', fontSize: 22, color: '#06b486' }}>
                      ddsurg@ddsurgical.com
                    </button>
                  </div>
                </div>
                {/* <div className="media get_item">
                  <i className="flaticon-chat"></i>
                  <div className="media-body">
                    <h6>Ping on Skype</h6>
                    <p style={{fontSize:22}}>saif052m</p>
                  </div>
                </div> */}
                {/* <div className="media get_item">
                  <i className="flaticon-pin"></i>
                  <div className="media-body">
                    <h6>Our Location</h6>
                    <p style={{fontSize:22}}>Sialkot, Pakistan</p>
                  </div>
                </div> */}

              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <Reveal effect="fadeInRight" duration={800}>
                <div className="input_form">
                  <SectionTitleTwo
                    stitle="Contact us"
                    btitle="Send us a message we will reply back"
                  />
                  <Form />
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactTwo;

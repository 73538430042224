import React, { useState, useEffect } from 'react';
import { Reveal } from 'react-reveal/';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import WebToAppPortfolioSlider from '../../component/Portfolio/WebToAppPortfolioSlider';
import TestimonialWebToApp from '../../component/Testimonial/TestimonialWebToApp';
import ShopifyAppFAQs from '../../component/ShopifyAppFAQs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShopifyToAppBanner = () => {
    const navigate = useNavigate();

    const [url, setUrl] = useState('')
    const [expandedItem, setExpandedItem] = useState(-1);
    const [showFeatures, setShowFeatures] = useState(0);
    const features = [
        {
            title: 'OneSignal Notifications',
            icon: require('../../image/feature1.png'),
            desc: 'Send unlimited push notifications via OneSignal Panel.'
        },
        {
            title: 'Admob Banner Ads',
            icon: require('../../image/feature2.png'),
            desc: 'Earn extra income using admob banner ads by Google.'
        },
        {
            title: 'Splash Screen',
            icon: require('../../image/f3.png'),
            desc: 'A professional looking splash screen with your logo and branding.'
        },
        {
            title: 'Pull to Refresh',
            icon: require('../../image/f5.png'),
            desc: 'Native pull to refresh feature to reload the website.'
        },
        {
            title: 'Bottom Tabs (Menu)',
            icon: require('../../image/f7.png'),
            desc: 'We can add bottom tabs in the app upon request. Giving extra native look and navigation options.'
        },
        {
            title: 'App Sharing',
            icon: require('../../image/f9.png'),
            desc: 'Users can share your app on social medias growing your audience.'
        },
        {
            title: 'No Internet Screen',
            icon: require('../../image/f10.png'),
            desc: 'A cool No Internet Screen whenever user is offline.'
        },
        {
            title: 'Loading Animation',
            icon: require('../../image/f11.png'),
            desc: 'A loading indicator whenever the website is loading.'
        },
        {
            title: 'Rate App Dialog',
            icon: require('../../image/f12.png'),
            desc: 'Get 5 star ratings from your users boosting your rank on app stores.'
        },
        {
            title: 'Free Support',
            icon: require('../../image/support.png'),
            desc: 'Free support to keep your app flawless and smooth.'
        },
        {
            title: 'Playstore Publishing (Addon)',
            icon: require('../../image/f13.png'),
            desc: 'We will publish your app to google play store, managing all the technical stuff.'
        },
        {
            title: 'Apple Store Publishing (Addon)',
            icon: require('../../image/f14.png'),
            desc: 'We will publish your app to apple store, managing all the technical stuff.'
        },
    ]

    const showItem = (itemNo, item) => {
        return (
            <div className={itemNo % 2 != 0 ? 'web-to-app-feature-item-left' : ''} style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <button
                    className='web-to-app-feature-item'
                    style={{ border: '0px', textAlign: 'start', marginTop: 10, flex: 1, display: 'flex', alignItems: 'center', backgroundColor: '#f2f2f2', padding: 10, borderRadius: 50, minHeight: 90 }}
                    onClick={() => {
                        if (expandedItem == itemNo) {
                            setExpandedItem(-1)
                            return
                        }
                        setExpandedItem(itemNo)
                    }}>
                    <div style={{ display: 'flex', width: 70, height: 70, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', borderRadius: 40 }}>
                        <img
                            style={{ width: 40, height: 40 }}
                            src={item.icon} />
                    </div>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <div className='web-to-app-feature-item-txt'>
                            {item.title}
                        </div>
                    </div>
                    <div className={expandedItem == itemNo ? 'web-to-app-feature-item-arrow' : ''} style={{ display: 'flex', marginRight: 15 }}>
                        <img
                            style={{ width: 30, height: 30 }}
                            src={require('../../image/down-arrow.png')} />
                    </div>
                </button>
                {expandedItem == itemNo &&
                    <Reveal effect="fadeInUp" duration={1000}>
                        <div className='web-to-app-feature-item-txt' style={{ fontSize: 14, marginTop: 5 }}>
                            {item.desc}
                        </div>
                    </Reveal>
                }
            </div>
        )
    };

    const showStep = (step, stepTxt, stepDesc) => {
        return (
            <div className='step-parent' style={{ flex: 1, marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                <div className='step'>
                    <div>{step}</div>
                </div>
                <div className='step-txt' style={{ fontWeight: 'bold', fontSize: 18 }}>
                    {stepTxt}
                </div>
                <div className='step-txt' style={{ marginTop: 0, fontSize: 16, color: 'gray' }}>
                    {stepDesc}
                </div>
            </div>
        )
    };

    const notify = (msg) => toast(msg);

    const openChat = (type) => {
        if (url === '')
            window.open("https://api.whatsapp.com/send?text=I want to convert my website to a " + type + " App.&phone=+923074052841", '_blank')
        else
            window.open("https://api.whatsapp.com/send?text=I want to convert my website" + url + " to a " + type + " App.&phone=+923074052841", '_blank')
        // window.location.href = "whatsapp://send?text=I want to convert my website " + url + " to a " + type + " App.&phone=+923074052841"
    };

    return (
        <section className="" id="home" style={{}}>
            {/* <div className="home_bubble" style={{ visibility: window.innerWidth < 700 ? 'hidden' : 'visible' }}>
                <div className="bubble b_one"></div>
                <div className="bubble b_four"></div>
                <div className="bubble b_five"></div>
                <div className="bubble b_six"></div>
                <div className="bubble b_three"></div>
            </div> */}
            <button
                aria-label="Scroll to top"
                type="button"
                className="scroll-top"
                onClick={() => {
                    // openChat('Mobile')
                }}>
                {/* <IoIosArrowRoundUp /> */}
                <img
                    src={require('../../image/wa1.png')}
                    style={{ alignSelf: 'center', width: 35, height: 35, tintColor: '#04B486', marginTop: -3 }}
                    alt=""
                />
            </button>

            <div style={{ width: '100%', marginTop: 75, backgroundColor: '#0B2F3A', display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'center' }}>
                <div className="row">
                    <div className="intro-white">
                        <div className="">
                            <Reveal effect="fadeInUp">
                                <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', width: 85, height: 85, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', borderRadius: 50, marginBottom:25 }}>
                                        <img
                                            style={{ width: 50, height: 50, alignSelf: 'center' }}
                                            src={require('../../image/shopify.png')} />
                                    </div>
                                    <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: '#fff', marginLeft:20, marginRight:20 }}>
                                        Convert Your <span style={{ fontWeight: 'bold' }}>Shopify Store</span> to Mobile App Today
                                    </h3>
                                </div>
                                <div className="bubble b_three"></div>
                            </Reveal>
                            <Reveal effect="fadeInUp" duration={1500}>
                                <p className="mb_0" style={{ fontSize: 15, marginTop: -10, textAlign: 'center', color: '#fff', marginLeft:20, marginRight:20 }}>Enter your Shopify Store URL to see what your APP would look like on Android & iOS for FREE!</p>
                            </Reveal>

                            <FormGroup className='web-to-app-input-row' style={{ marginTop: -10, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                <div style={{ flex: 3, display: 'flex', alignItems: 'center', marginTop: 10, marginRight: 10 }}>
                                    <Input
                                        style={{ height: 60, minWidth: 300 }}
                                        type='text'
                                        placeholder='https://'
                                        value={url}
                                        onChange={(event) => {
                                            setUrl(event.target.value)
                                        }} />
                                    {url.length < 1 ?
                                        <button
                                            onClick={async () => {
                                                console.log('click');
                                                const text = await navigator.clipboard.readText();
                                                setUrl(text)
                                            }}
                                            style={{ color: '#04B486', border: '0px', backgroundColor: '#f2f2f2', marginLeft: -110, width: 100, textDecoration: 'underline' }}>
                                            Paste Link
                                        </button>
                                        :
                                        <button
                                            onClick={async () => {
                                                setUrl('')
                                            }}
                                            style={{ color: '#04B486', border: '0px', backgroundColor: '#f2f2f2', marginLeft: -70, width: 60, textDecoration: 'underline' }}>
                                            Clear
                                        </button>
                                    }
                                </div>
                                <div style={{ display: 'flex', minWidth: 280, flex: 1 }}>
                                    <Link
                                        onClick={() => {
                                            console.log('click');
                                            // openChat('Demo')
                                            if (url === '') {
                                                notify('Please enter store url!')
                                            }
                                            else {
                                                navigate('/shopify-builder', { replace: true });
                                            }
                                        }}
                                        className='btn my-button' style={{ backgroundColor: '#04B486', border: '0px solid white', color: '#fff', marginTop: 10, width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold' }}>
                                        Request Now, IT'S FREE
                                    </Link>
                                </div>
                            </FormGroup>

                            <div style={{display:'flex', marginTop:30, alignSelf:'center', alignItems:'center',justifyContent:'center'}}>
                                <div style={{ display: 'flex', width: 55, height: 55, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', borderRadius: 40 }}>
                                    <img
                                        style={{ width: 35, height: 35 }}
                                        src={require('../../image/android.png')} />
                                </div>
                                <div style={{ display: 'flex', marginLeft:10, width: 55, height: 55, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', borderRadius: 40 }}>
                                    <img
                                        style={{ width: 35, height: 35 }}
                                        src={require('../../image/apple.png')} />
                                </div>
                            </div>

                            {/* <div style={{ alignSelf: 'center', marginTop: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img
                                    className='web-to-app-image'
                                    style={{}}
                                    src={require('../../image/web-to-app2.svg').default} />
                            </div> */}

                        </div>
                    </div>

                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ marginBottom: 30, fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                            Benefits You Will Get!
                        </h3>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {features.map((item, index) => {
                                return showItem(index, item)
                            })}
                        </div>
                    </Reveal>
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ marginBottom: 30, fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                            Get Your App in Just 3 Steps!
                        </h3>
                        <div
                            style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 10, alignItems: 'flex-start', justifyContent: 'center' }}>
                            {showStep(1, 'Submit Url', 'Enter the store link you would like to convert into a mobile app.')}
                            {showStep(2, 'Get Free Demo', 'I will give a FREE demo within few hours.')}
                            {showStep(3, 'Upload to Stores', 'Go ahead and upload the app on Apps Stores or let me do it for you.')}
                        </div>
                    </Reveal>
                </div>
            </div>

            <div id='showcase' style={{ width: '100%', backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0 }}>
                <div className="intro-white">
                    <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                        Examples of My Work!
                    </h3>
                    <WebToAppPortfolioSlider />
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <h3 className="web-to-app-title" style={{ marginBottom: 30, fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                        Who Am I & What My Clients Say
                    </h3>
                    <img
                        className='freelancer-img'
                        style={{ width: '100%' }}
                        src={require('../../image/freelancer.jpg')} />
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 5, marginLeft: 20, marginRight: 20 }}>
                        <button
                            style={{ flex: 1, marginTop: 10, border: '0px', minWidth: 300, fontSize: 20, backgroundColor: '#0e87d0', color: 'white', borderRadius: 5, height: 60 }}
                            onClick={() => {
                                window.open('https://www.freelancer.com/u/saif0347', '_blank');
                                // window.location.href = 'https://www.freelancer.com/u/saif0347'
                            }}>
                            View My Freelaner Profile
                        </button>
                        <button
                            className='ml-15'
                            style={{ flex: 1, marginTop: 10, minWidth: 300, fontSize: 20, border: '1px solid #0e87d0', backgroundColor: 'white', color: '#0e87d0', borderRadius: 5, height: 60 }}
                            onClick={() => {
                                window.open('https://www.freelancer.com/u/saif0347', '_blank');
                                // window.location.href = 'https://www.freelancer.com/u/saif0347'
                            }}>
                            View Client Reviews
                        </button>
                    </div>
                    <TestimonialWebToApp />
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                            Let's Do It!
                        </h3>
                        <div className="bubble b_three"></div>
                    </Reveal>
                    <Reveal effect="fadeInUp" duration={1500}>
                        <p className="mb_0" style={{ fontSize: 16, marginTop: -5, textAlign: 'center', color: '#545454' }}>
                            Please enter your store url to convert into mobile app.
                        </p>
                    </Reveal>

                    <FormGroup className='web-to-app-input-row' style={{ marginTop: -10, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        <div style={{ flex: 3, display: 'flex', alignItems: 'center', marginTop: 10, marginRight: 10 }}>
                            <Input
                                style={{ height: 60, minWidth: 300 }}
                                type='text'
                                placeholder='https://'
                                value={url}
                                onChange={(event) => {
                                    setUrl(event.target.value)
                                }} />
                        </div>
                        <div style={{ display: 'flex', minWidth: 280, flex: 1 }}>
                            <Link
                                onClick={() => {
                                    console.log('click');
                                    openChat('Demo')
                                }}
                                className='btn my-button' style={{ backgroundColor: '#04B486', color: 'white', marginTop: 10, width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                Request FREE Demo Now
                            </Link>
                        </div>
                    </FormGroup>
                </div>
            </div>

            <div id='FAQ' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49' }}>
                            Freqently Asked Questions
                        </h3>
                    </Reveal>
                    <ShopifyAppFAQs />
                    <button
                        style={{ alignSelf: 'center', width: '100%', marginTop: 35, textAlign: 'center', fontSize: 22, border: '0px', backgroundColor: 'transparent', color: '#04B486', borderRadius: 5 }}>
                        Any other questions? Let's Discuss in Whatsapp
                    </button>
                </div>
            </div>

            <ToastContainer />
        </section>
    )
}

export default ShopifyToAppBanner;
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-scroll';
import showToast from '../toast';
import CreditCardInput from 'react-credit-card-input';
import FooterTwo from '../../component/FooterTwo';
import NavbarShopifyToApp from './../Navbar/NavbarShopifyToApp';
import jhonData from '../../component/jhonData';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'
import Api from '../Api/api'
import { useNavigate } from 'react-router-dom';
import GooglePayButton from '@google-pay/button-react';
import GLOBAL from '../Global'
import db, { storage } from '../../firebase/firebase';
import moment from 'moment';

const Payment = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    const [platform, setPlatfrom] = useState(0);
    const [price, setPrice] = useState(49);
    const [name, setName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCVC] = useState('');
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step1) {
                setPlatfrom(shopifyData.step1.platform)
                if (shopifyData.step1.platform == 1) {
                    setPrice(29)
                }
                else if (shopifyData.step1.platform == 2) {
                    setPrice(49)
                }
                else if (shopifyData.step1.platform == 3) {
                    setPrice(69)
                }
            }
        }
    }, [])

    const updateValue = (key, value) => {
        //
    };

    const getWidth = () => {
        return window.innerWidth < 400 ? 300 : 400
    };

    const getPlatformName = () => {
        if (platform == 1)
            return 'Android'
        else if (platform == 2)
            return 'iOS'
        else if (platform == 3)
            return 'Android & iOS'
    };

    const cardPayment = () => {
        // placeOrder('', '')
        if (cardNumber === '' || expiry === '' || cvc === '' || name === '') {
            showToast('error', 'Please fill card info!')
            return
        }
        setLoader(true)
        Api.getCredentials(
            (credentials) => {
                if (credentials.stripe) {
                    let key = credentials.stripe.s_key
                    Api.stripeToken(key, cardNumber, expiry.split('/')[0], '20' + expiry.split('/')[1], cvc,
                        (response) => {
                            let token = response.id
                            console.log('token: ' + token);
                            if (token != undefined && token != null && token !== '') {
                                Api.stripePayment(key, token, price,
                                    (response) => {
                                        setLoader(false)
                                        console.log('status: ' + response.status);
                                        if (response.status.toLowerCase() === 'succeeded') {
                                            showToast('success', 'Payment Success!')
                                            // place order
                                            placeOrder('card', response)
                                        }
                                    },
                                    (error) => {
                                        setLoader(false)
                                        showToast('error', 'Payment failed!')
                                    });
                            }
                            else {
                                setLoader(false)
                                showToast('error', 'Payment failed!')
                            }
                        },
                        (error) => {
                            setLoader(false)
                            showToast('error', 'Payment failed!')
                        });
                }
                else {
                    this.setState({ loader: false });
                    showToast('error', 'Payment failed!')
                }
            },
            (error) => {
                setLoader(false)
                showToast('error', error)
            }
        )
    };

    // payment done
    const placeOrder = (paymentMethod, paymentResponse) => {
        let shopifyDataTemp = {
            ...shopifyData,
            step1: {
                ...shopifyData.step1,
                name: name
            }
        }
        shopifyDataTemp.paymentMethod = paymentMethod
        shopifyDataTemp.paymentResponse = paymentResponse
        shopifyDataTemp.added_date = moment() + ''

        console.log('before order: ' + JSON.stringify(shopifyDataTemp));

        // add order
        Api.placeShopifyOrder(shopifyDataTemp,
            (orderId) => {
                console.log('order placed: ' + orderId);
                uploadLogo(orderId)
            },
            (error) => {
                // error
            })

        // send email
        sendEmailToCustomer()
        sendEmailToAdmin()

        // show success screen
        navigate('/shopify-success', { replace: false });
    };

    const uploadLogo = (orderId) => {
        let file = GLOBAL.shopifyLogo
        if (file != null) {
            let fileName = moment() + '' + file.name
            console.log('upload file name: ' + fileName);
            uploadPhoto(
                'shopify/' + fileName,
                file,
                (url) => {
                    // update icon info
                    db.collection('shopifyOrders').doc(orderId).set({ logo: url }, { merge: true });
                }
            );
        }
        else {
            console.log('null file');
        }
    };

    const uploadPhoto = (path, photo, onSuccess) => {
        const uploadTask = storage.ref(path).put(photo);
        uploadTask.on('state_changed',
            (snapshot) => {
                // const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
            (error) => {
                console.log(error);
            },
            () => {
                storage.ref(path).getDownloadURL().then((url) => {
                    console.log('logo url: ' + url);
                    onSuccess(url);
                })
            });
    };

    const sendEmailToCustomer = () => {
        let mailParams = {
            "accessToken": '03X4H03hf8mNX3vmdhiLI',
            "service_id": 'service_vkaxqvg',
            "template_id": 'template_lv3syqu',
            "user_id": 'Hrb9PrGzCxo7wOfqg',
            "template_params": {
                "email": shopifyData.step1.email,
                "message": 'Your order for ' + getPlatformName() + ' is confirmed. Within 24 hours you will get a testable build you can install on your device and test. Please stay tuned we will be working together :)',
            }
        }
        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mailParams)
        })
            .then(response => response.text())
            .then(result => {
                console.log('email sent: ' + result.text);
            })
            .catch(err => {
                console.log(err)
            });
    };

    const sendEmailToAdmin = () => {
        let mailParams = {
            "accessToken": '03X4H03hf8mNX3vmdhiLI',
            "service_id": 'service_vkaxqvg',
            "template_id": 'template_mwyz8jd',
            "user_id": 'Hrb9PrGzCxo7wOfqg',
            "template_params": {
                "from_name": "ShopifyToApp",
                "email": "",
                "message": 'You got a new Shopify App order!',
            }
        }
        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mailParams)
        })
            .then(response => response.text())
            .then(result => {
                console.log('email sent: ' + result.text);
            })
            .catch(err => {
                console.log(err)
            });
    };

    return (
        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>

            <button
                aria-label="Scroll to top"
                type="button"
                className="scroll-top"
                onClick={() => {
                    window.open("https://api.whatsapp.com/send?text=I want to convert my shopify store to a Mobile App.&phone=+923074052841", '_blank')
                }}>
                <img
                    src={require('../../image/wa1.png')}
                    style={{ alignSelf: 'center', width: 35, height: 35, tintColor: '#04B486', marginTop: -3 }}
                    alt=""
                />
            </button>

            <NavbarShopifyToApp mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" />
            <div style={{ display: 'flex', marginTop: 100, height: 70, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    style={{ width: 70, height: 70 }}
                    src={require('../../image/shopify.png')} />
                <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', marginTop: 30, marginLeft: 10, color: '#113E49' }}>
                    Mobile App Builder
                </h3>
            </div>
            <div style={{ paddingBottom: 50, maxWidth: 500, display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                <div
                    className='col'
                    style={{ flex: 1, alignItems: 'center', backgroundColor: '#fafafa', border: '1px solid #f2f2f2', marginTop: 20, display: 'flex', flexDirection: 'column', borderRadius: 5 }}>

                    <div style={{ color: 'black', marginTop: 20, fontSize: 15 }}>
                        You will be charged <span style={{ fontWeight: 'bold' }}>${price}</span> for {getPlatformName()} App
                    </div>

                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'stretch', alignSelf: 'center', border: '1px solid lightgray' }}>
                        <CreditCardInput
                            cardNumberInputProps={{ value: cardNumber, onChange: (e) => { setCardNumber(e.target.value) } }}
                            cardExpiryInputProps={{ value: expiry, onChange: (e) => { setExpiry(e.target.value) } }}
                            cardCVCInputProps={{ value: cvc, onChange: (e) => { setCVC(e.target.value) } }}
                            fieldClassName="input"
                            containerStyle={{ width: getWidth() }}
                        />
                    </div>

                    <input
                        type='text'
                        value={name}
                        placeholder='Name on Card'
                        style={{ width: getWidth(), paddingLeft: 15, marginTop: 10, border: '1px solid lightgray', height: 40 }}
                        onChange={(event) => {
                            setName(event.target.value)
                            updateValue('business_name', event.target.value)
                        }} />

                    <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                        <img
                            style={{ width: 20, height: 20 }}
                            src={require('../../image/lock.png')} />
                        <div style={{ marginLeft: 5, fontSize: 14 }}>
                            Secured by Stripe
                        </div>
                    </div>

                    <div style={{ width: getWidth(), display: 'flex', flexDirection: 'column' }}>
                        <Link
                            onClick={() => {
                                cardPayment()
                            }}
                            className='btn my-button' style={{ height: 50, backgroundColor: '#04B486', marginTop: 10, color: 'white', width: '100%', padding: 12, fontSize: 16, fontStyle: 'bold', border: 'none', borderRadius: 0 }}>
                            Pay & Confirm Order
                        </Link>
                        <div style={{ marginTop: 5, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ height: 50, display: 'flex', backgroundColor: 'black', alignSelf: 'center', alignItems: 'center' }}>
                                <GooglePayButton
                                    buttonType='pay'
                                    environment='PRODUCTION'
                                    buttonSizeMode='fill'
                                    paymentRequest={{
                                        apiVersion: 2,
                                        apiVersionMinor: 0,
                                        allowedPaymentMethods: [
                                            {
                                                type: 'CARD',
                                                parameters: {
                                                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                                    allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"]
                                                },
                                                tokenizationSpecification: {
                                                    type: 'PAYMENT_GATEWAY',
                                                    parameters: {
                                                        gateway: 'example',
                                                        gatewayMerchantId: 'exampleGatewayMerchantId',
                                                    },
                                                },
                                            },
                                        ],
                                        merchantInfo: {
                                            merchantId: 'BCR2DN4TQ3M2JKRS',
                                            merchantName: 'Saiftech',
                                        },
                                        transactionInfo: {
                                            totalPriceStatus: 'FINAL',
                                            totalPriceLabel: 'Total',
                                            totalPrice: '0.1',
                                            currencyCode: 'USD',
                                            countryCode: 'US',
                                        },
                                    }}
                                    onLoadPaymentData={paymentRequest => {
                                        console.log('load payment data', paymentRequest);
                                    }}
                                />
                            </div>
                            <Link
                                onClick={() => {
                                    window.location.href = 'https://www.freelancer.com/u/saif0347'
                                }}
                                className='btn my-button'
                                style={{ marginLeft: 5, flex: 1, height: 50, backgroundColor: 'white', border: '1px solid #2ab2fe', color: 'white', padding: 12, fontSize: 16, fontStyle: 'bold', borderRadius: 0 }}>
                                <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                    <img
                                        style={{ width: 28, objectFit: 'contain' }}
                                        src={require('../../image/freelancer.png')} />
                                    <div style={{ color: '#2ab2fe', marginLeft: 3 }}>
                                        Hire on Freelancer.com
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/* <button
                            style={{ marginTop: 12, alignSelf: 'center', fontSize: 16, color: 'black', fontFamily: 'sans-serif', color: '#04B486' }}>
                            Unable to Pay? Contact Us
                        </button> */}

                    </div>

                    <div style={{ width: '100%', height: 1.5, backgroundColor: '#f2f2f2', marginTop: 30 }} />

                    <img
                        style={{ alignSelf: 'center', width: window.innerWidth < 800 ? '100%' : '100%' }}
                        src={require('../../image/banner1.jpg')} />

                    <div style={{ paddingTop: 0, marginBottom: 20, fontSize: 16, color: 'black', fontFamily: 'sans-serif', marginLeft: 20, marginRight: 20 }}>
                        <span style={{ color: 'orange' }}>Note:</span> You will get testable app within 24 hours. And if you do not like the mobile app, we will refund 100% payment without any question.
                    </div>

                </div>

            </div>
            <FooterTwo jhonData={jhonData} />
        </div>
    )
}

export default Payment; 
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Link } from 'react-scroll';
import showToast from '../toast';
import FooterTwo from '../../component/FooterTwo';
import NavbarShopifyToApp from './../Navbar/NavbarShopifyToApp';
import jhonData from '../../component/jhonData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'

const Step1 = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    let profilePercentage = 25

    const [url, setUrl] = useState('');
    // const [appName, setAppName] = useState('');
    const [email, setEmail] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [platform, setPlatfrom] = useState(1);

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step1) {
                setUrl(shopifyData.step1.url)
                setEmail(shopifyData.step1.email)
                setWhatsapp(shopifyData.step1.whatsapp)
                setPlatfrom(shopifyData.step1.platform)
            }
        }
    }, [])

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>

            <button
                aria-label="Scroll to top"
                type="button"
                className="scroll-top"
                onClick={() => {
                    window.open("https://api.whatsapp.com/send?text=I want to convert my shopify store to a Mobile App.&phone=+923074052841", '_blank')
                }}>
                <img
                    src={require('../../image/wa1.png')}
                    style={{ alignSelf: 'center', width: 35, height: 35, tintColor: '#04B486', marginTop: -3 }}
                    alt=""
                />
            </button>

            <NavbarShopifyToApp mClass="menu_two" mainlogo="logo-3.png" stickylogo="logo-3.png" />
            <div style={{ display: 'flex', marginTop: 100, height: 70, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    style={{ width: 70, height: 70 }}
                    src={require('../../image/shopify.png')} />
                <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', marginTop: 30, marginLeft: 10, color: '#113E49' }}>
                    Mobile App Builder
                </h3>
            </div>
            <div className='shopify-step-parent' style={{ height: '100vh', display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                <div style={{ height: 8, width: '100%', backgroundColor: '#f2f2f2', marginTop: 50, borderRadius: 4 }}>
                    <div style={{ height: 8, backgroundColor: '#04B486', width: profilePercentage + '%', borderRadius: 4 }} />
                </div>

                <div
                    className='col'
                    style={{ flex: 1, marginTop: 40, display: 'flex', flexDirection: 'column' }}>

                    <TextField
                        id="outlined-basic"
                        label="Store Url"
                        sx={{ fontSize: 12 }}
                        variant="outlined"
                        value={url}
                        size='medium'
                        onChange={(event) => {
                            setUrl(event.target.value)
                        }} />

                    {/*<TextField
                        id="outlined-basic"
                        label="App Name"
                        style={{ marginTop: 10 }}
                        variant="outlined"
                        value={appName}
                        size='medium'
                        onChange={(event) => {
                            setAppName(event.target.value)
                            updateValue('app_name', event.target.value)
                        }} /> */}

                    <TextField
                        id="outlined-basic"
                        label="Email"
                        style={{ marginTop: 10 }}
                        variant="outlined"
                        value={email}
                        size='medium'
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }} />

                    <TextField
                        id="outlined-basic"
                        label="Whatsapp (Optional)"
                        style={{ marginTop: 10 }}
                        type='phone'
                        variant="outlined"
                        value={whatsapp}
                        size='medium'
                        onChange={(event) => {
                            setWhatsapp(event.target.value)
                        }} />

                    <div style={{ marginTop: 10, fontSize: 16 }}>
                        Choose Platform
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                        <button
                            onClick={() => {
                                setPlatfrom(1)
                            }}
                            style={{ display: 'flex', flex: 1, color: 'gray', backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: 10, paddingTop: 15, paddingBottom: 15, border: platform == 1 ? '2px solid #04B486' : '1px solid lightgray', borderRadius: 10 }}>
                            <img
                                style={{ width: 40, height: 40 }}
                                src={require('../../image/android.png')} />
                            <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 5 }}>
                                Android
                            </div>
                        </button>
                        <button
                            onClick={() => {
                                setPlatfrom(2)
                            }}
                            style={{ display: 'flex', flex: 1, color: 'gray', backgroundColor: 'white', marginLeft: 10, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: 10, paddingTop: 15, paddingBottom: 15, border: platform == 2 ? '2px solid #04B486' : '1px solid lightgray', borderRadius: 10 }}>
                            <img
                                style={{ width: 40, height: 40 }}
                                src={require('../../image/apple.png')} />
                            <div style={{ fontSize: 16, fontWeight: 'bold', marginTop: 5 }}>
                                iOS
                            </div>
                        </button>
                        <button
                            onClick={() => {
                                setPlatfrom(3)
                            }}
                            style={{ display: 'flex', flex: 1, color: 'gray', backgroundColor: 'white', marginLeft: 10, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: 10, paddingTop: 15, paddingBottom: 15, border: platform == 3 ? '2px solid #04B486' : '1px solid lightgray', borderRadius: 10 }}>
                            <div style={{ display: 'flex' }}>
                                <img
                                    style={{ width: 40, height: 40, marginTop: 2 }}
                                    src={require('../../image/android.png')} />
                                <img
                                    style={{ width: 40, height: 40, marginLeft: 3 }}
                                    src={require('../../image/apple.png')} />
                            </div>
                            <div style={{ fontSize: 16, textAlign: 'center', fontWeight: 'bold', marginTop: 5 }}>
                                Both
                            </div>
                        </button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: 280, flex: 1 }}>
                        <Link
                            onClick={() => {
                                console.log('click');
                                if (url === '') {
                                    showToast('error', 'Please fill details!')
                                    return
                                }
                                else if (email === '') {
                                    showToast('error', 'Please fill details!')
                                    return
                                }
                                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                                    showToast('error', 'Please enter a valid email!')
                                    return
                                }
                                let data = {
                                    url: url,
                                    email: email,
                                    whatsapp: whatsapp,
                                    platform: platform
                                }
                                let shopifyDataTemp = { ...shopifyData }
                                shopifyDataTemp['step1'] = data
                                dispatch(Actions.setShopifyData(shopifyDataTemp))
                                navigate('/shopify-step2', { replace: false });
                            }}
                            className='btn my-button' style={{ height: 55, backgroundColor: '#04B486', marginTop: 25, color: 'white', width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                            Next
                        </Link>
                    </div>

                </div>

            </div>
            <FooterTwo jhonData={jhonData} />
        </div>
    )
}

export default Step1;